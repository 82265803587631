<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="LBLDETAIL">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable && !disabled" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="targetDept"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveTargetDept"
              @btnCallback="saveTargetDeptCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <!--결과요약-->
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="15"
              label="LBL0010151"
              name="resultRemark"
              v-model="targetDept.resultRemark">
            </c-textarea>
          </div>
          <div class="col-12">
            <!--결과보고서-->
            <c-upload 
              label="LBL0010152"
              :attachInfo="attachInfo"
              :editable="editable&&!disabled">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'internal-result-attach',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',
      }),
    },
    targetDept: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',  // 내부심사 피심사팀 일련번호
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        deptCd: '',  // 피심사부서코드
        saiInternalActionTargetDeptStepCd: '',  // 내부심사 피심사팀 진행상태
        resultRemark: '',  // 결과요약
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklist: [], // 체크리스트 결과
        auditTeams: [], // 심사팀
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '', // 판정에서 데이터가 저장되어 키가 있는 상황임으로 사용하지 않음
        taskClassCd: 'INTERNAL_TARGET_DEPT',
        taskKey: '',
      },
      editable: true,
      isSave: false,
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.internal.targetDept.update.url;

      this.attachInfo.taskKey = this.$_.clone(this.popupParam.saiInternalActionTargetDeptId)
    },
    saveTargetDept() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.targetDept.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetDeptCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('stepup')
    },
  }
};
</script>